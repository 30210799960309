<template>
  <div v-loading.fullscreen.lock="loading">
    <ProcessTable
      ref="processTableRef"
      type="accounts"
      group-name="purpose"
      :group-capitalize="true"
      :height="tableHeight"
    />
  </div>
</template>

<script setup lang="ts">
import { useGlobalStore } from '@/stores/modules/global'
import ProcessTable from '../components/ProcessTable.vue'
import { useReportsTableConfigStore } from '@/stores/modules/reports/tableConfig'

const route = useRoute()
const loading = ref(false)
const processTableRef = ref()
const globalStore = useGlobalStore()
const tableConfigStore = useReportsTableConfigStore()

const tableHeight = computed(() => {
  return globalStore.showUpGradeInfo ? `calc(100vh - 177px)` : `calc(100vh - 146px)`
})

watch(
  route,
  async (newValue) => {
    loading.value = true
    if (newValue.name === 'reports-sources-accounts') {
      tableConfigStore.processTableConfig = []
      await tableConfigStore.getProcessTableConfig('accounts')
    }
    setTimeout(() => {
      loading.value = false
    }, 300)
  },
  { immediate: true, deep: true }
)
</script>

<style lang="scss" scoped>
:deep(.elv-financial-process-table) {
  padding-top: 0px;
}
</style>
